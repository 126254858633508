import {Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import ErrorPage from "./pages/404";
import AndroidPage from "./pages/AndroidImages"
import IosPage from "./pages/IphoneImages"

function App() {
    return (
        // <div className="pages">
            <Routes>
                <Route path="*" Component={ErrorPage}/>
                <Route path="/" Component={Home}/>
                <Route path="/images">
                    <Route path="/images/ios" Component={IosPage}/>
                    <Route path="/images/android" Component={AndroidPage}/>
                </Route>
            </Routes>
        // </div>
    );
}

export default App;
