import React from "react";
import ImageDownload from "../components/ImageDownload";
import IphoneImage1 from "../assets/ios-images/509575222-iphone-12-1170x2532-1.png"
import IphoneImage2 from "../assets/ios-images/509575232-iphone-12-1170x2532-2.png"
import IphoneImage3 from "../assets/ios-images/509575272-iphone-12-1170x2532-4.png"
import IphoneImage4 from "../assets/ios-images/509575259-iphone-12-1170x2532-3.png"

export default function IphoneImages() {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={IphoneImage1} filename="iphone-12-1"/>
                    <ImageDownload imageUrl={IphoneImage2} filename="iphone-12-2"/>
                </div>
                <div className="images-second-row">
                    <ImageDownload imageUrl={IphoneImage3} filename="iphone-12-3"/>
                    <ImageDownload imageUrl={IphoneImage4} filename="iphone-12-4"/>
                </div>
            </div>
        </>
    )
}