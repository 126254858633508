import React from "react";
import ImageDownload from "../components/ImageDownload";
import AndroidImage1 from "../assets/android-images/509575151-samsung-s21-a72-1080x2400-1.png"
import AndroidImage2 from "../assets/android-images/509575154-samsung-s21-a72-1080x2400-2.png"
import AndroidImage3 from "../assets/android-images/509575180-samsung-s21-a72-1080x2400-4.png"
import AndroidImage4 from "../assets/android-images/509575172-samsung-s21-a72-1080x2400-3.png"

export default function AndroidImages() {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={AndroidImage1} filename="samsung-s21-1"/>
                    <ImageDownload imageUrl={AndroidImage2} filename="samsung-s21-2"/>
                </div>
                <div className="images-second-row">
                    <ImageDownload imageUrl={AndroidImage3} filename="samsung-s21-3"/>
                    <ImageDownload imageUrl={AndroidImage4} filename="samsung-s21-4"/>
                </div>
            </div>
        </>
    )
}