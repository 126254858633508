import React from "react";
import AndroidImage from "../assets/button/androidbutton.svg"
import IosImage from "../assets/button/ios-button.svg"
import Isvg from "react-inlinesvg"
import {Link} from "react-router-dom";

export default function Home() {
    return (
        <div className="home">
            <div className="home-section">
                <Link to="/images/ios">
                    <Isvg src={IosImage}/>
                </Link>
                <Link to="/images/android">
                    <Isvg src={AndroidImage}/>
                </Link>
            </div>
        </div>
    )
}